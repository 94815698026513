<script setup>
import { useRouter } from "vue-router/auto";

const props = defineProps({
  prependIcon: { type: String, default: null },
  icon: { type: String, default: "mdi-plus" },
  color: { type: String, default: "primary" },
  size: { type: String, default: "large" },
  to: { type: String, default: null },
  class: { type: String, default: "rounded-pill" },
  label: { type: String, default: null },
});

const router = useRouter();
</script>

<template>
  <div class="fab">
    <v-btn
      v-if="!props.label"
      :icon="props.icon"
      :size="props.size"
      :to="props.to"
      :color="props.color"
      :class="`${props.class}`"
    >
    </v-btn>
    <v-btn
      v-if="props.label"
      :prepend-icon="props.prependIcon"
      :size="props.size"
      :to="props.to"
      :color="props.color"
      :class="`${props.class}`"
    >
      {{ props.label }}
    </v-btn>
  </div>
</template>

<style scoped>
.fab {
  position: fixed;
  bottom: 30px;
  right: 24px;
}
</style>
